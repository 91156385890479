import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import Iframe from 'react-iframe'
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import firebase, { firestore } from '../../firebase';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TuneIcon from '@material-ui/icons/Tune';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import DescriptionIcon from '@material-ui/icons/Description';
import MapIcon from '@material-ui/icons/Map';

import Toolbar from '@material-ui/core/Toolbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import CheckCircle from '@material-ui/icons/CheckCircle';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import { green } from '@material-ui/core/colors';
import { grey } from '@material-ui/core/colors';


const styles = theme => ({
  root: {
    padding: theme.spacing(3, 2),
    maxWidth: 1024,
    marginLeft: 'auto',
    marginRight: 'auto',
    margin: 20
  },
  paper: {
    padding: theme.spacing(1),
  },
  tabBarButtonContainer: {
      marginBottom: theme.spacing(2),
  },
  grid: {
    margin: 20,
  },
  card: {
    maxWidth: 345,
    margin: 12,
  },
  responsive_image: {
    width: '100%',
  },
  gridList: {
      maxWidth: 1024,
      height: 450,
  },
  nav_button: {

    float: 'right',
  },
  backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  rentedIcon: {
    color: green[500],
    fontSize:14,
    marginLeft: theme.spacing(3),
    //verticalAlign: 'middle'
  },
  notRentedIcon: {
    color: grey[500],
    fontSize:14,
    marginLeft: theme.spacing(3),
    //verticalAlign: 'middle'
  },
  rentedLabel: {
    color: green[500],
    fontSize:14,
  },
  notRentedLabel: {
    color: grey[500],
    fontSize:14,
  },
  fileUploadInput: {
    display: 'none',
  },
});


class PropertyContent extends React.Component {

  constructor(props){
    super(props);
    const propertyID = this.props.match.params.propertyID;
    this.state = {
      property: null,
      propertyID: null,
      files: null,
      toHomepage: false,
      selectedTab: 0,
      uploadCount: 0,
    }
    var that = this;

    firestore.collection('properties').doc(propertyID).get().then(propertySnapshot => {
        if(propertySnapshot.exists){
            that.getFileList(propertyID);
            that.setState({propertyID: propertyID, property: propertySnapshot});
        }
    });

  }

  getIconForFileType(fileType){
    if(fileType === 'application/pdf'){
      return(<PictureAsPdfIcon />);
    }
    return(<InsertDriveFileIcon />);

  }


  getFileList(propertyID){

    if(!propertyID){
      return(false);
    }
    var that = this;
    const { user } = this.props;
    // Get a reference to the storage service, which is used to create references in your storage bucket
    var storage = firebase.storage();

    // Create a storage reference from our storage service
    var storageRef = storage.ref();

    // Create a reference under which you want to list
    var listRef = storageRef.child('/' + propertyID + '/files');

    // Find all the prefixes and items.
    listRef.listAll().then(function(res) {
      let fileList = []; //that.state.files;
      res.items.forEach(function(itemRef) {
        // All the items under listRef.
        itemRef.getDownloadURL().then(function(downloadUrl){

            itemRef.getMetadata().then(function(metadata){
                var fileRef = { ...metadata, downloadUrl };
                fileList.push(fileRef);
                that.setState({files: fileList});
            });
        });


      });
    }).catch(function(error) {
      console.error('Error getting files.', error);
    });

  }


  rentedIcon(isRented){
    const { classes } = this.props;
    if(isRented === true){
      return(
        <span><CheckCircle className={ classes.rentedIcon } /> <Typography className={ classes.rentedLabel } component="span">Rented</Typography></span>
      );
    } else {
      return(
        <span><CheckCircleOutline className={ classes.notRentedIcon } /> <Typography className={ classes.notRentedLabel } component="span">Not Rented</Typography></span>
      );
    }

  }

  tabProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  }

  tabContainerProps(index){
    return {
      id: `scrollable-force-tabpanel-${index}`,
      'arial-labelledby': `scrollable-force-tab-${index}`,
      'value': index
    }
  }

  handleTabChange = (event, newValue) => {
     if(this.state.selectedTab !== newValue){
       this.setState({selectedTab: newValue});
     }
   };

  handleRetunHome = () => {
    this.setState(() => ({
      toHomepage: true
    }))
  }

  handleUploadButtonClick = (event) => {
    this.fileUploadInputControl.click();
  }

  handleFileList = (event) => {
    const formData = new FormData(document.querySelector("#fileUploadForm"));
    var values = {};
    for(var pair of formData.entries()){
      if(values[pair[0]]) {
        if(!(values[pair[0]] instanceof Array)) {
         values[pair[0]] = new Array(values[pair[0]]);
        }
        values[pair[0]].push(pair[1]);
       } else {
         values[pair[0]] = pair[1];
       }
    }
    var propertyID = this.state.propertyID;
    //var listRef = storageRef.child('/' + propertyID + '/files');
    var path = "/" + propertyID + "/files/";

    if(Array.isArray(values.fileUpload)){
      this.setState({uploadCount: values.fileUpload.length});
      values.fileUpload.forEach((file) => {
          var callbacks = { progress: this.progress, success: this.success, error: this.error };
          this.saveFile(path, file, file.name, callbacks);
      });
    } else {
      this.setState({uploadCount: 1});
      var callbacks = { progress: this.progress, success: this.success, error: this.error };
      this.saveFile(path, values.fileUpload, values.fileUpload.name, callbacks);
    }

  };

  saveFile = (path, file, filename, callbacks) => {
    var ref = firebase.storage().ref();
    if(!callbacks) callbacks = {};
    if(file.type) {
      callbacks.success = callbacks.success || console.log;
      callbacks.progress = callbacks.progress || console.log;
      callbacks.error = callbacks.error || console.error;

      // Create the file metadata
      var metadata = {
        contentType: file.type
      };

      // Upload file and metadata to the object
      var uploadTask = ref.child(path + filename).put(file, metadata);
      uploadTask.on('state_changed', callbacks.progress, callbacks.error, callbacks.success);

      return uploadTask.then(function(snapshot) {
        return snapshot.ref.getDownloadURL();
      });
    }
  }

  success = (event) => {
    var currentUploadCount = (this.state.uploadCount - 1);
    console.log('Success. Files Remaining to Upload: ', currentUploadCount);
    this.setState({uploadCount: currentUploadCount});
    if(currentUploadCount <= 0){
      this.getFileList(this.state.propertyID);
    }
  }

  error = (event) => {
    var currentUploadCount = (this.state.uploadCount - 1);
    console.log('Error. Files Remaining to Upload: ', currentUploadCount);
    this.setState({uploadCount: currentUploadCount});
    if(currentUploadCount <= 0){
      this.getFileList(this.state.propertyID);
    }
  }

  progress = (snapshot) => {
    // Observe state change events such as progress, pause, and resume
    console.log(snapshot.ref.name);
    if((snapshot.ref !== undefined) && (snapshot.ref.name !== undefined)){
      console.log("Progress for filename: ", snapshot.ref.name);
    }

    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.log('Upload is ' + progress + '% done');
    switch (snapshot.state) {
      case firebase.storage.TaskState.PAUSED: // or 'paused'
        console.log('Upload is paused');
        break;
      case firebase.storage.TaskState.RUNNING: // or 'running'
        console.log('Upload is running');
        break;
      default:
        console.log('Something else happened with the upload...');
    }
  }

    renderFileList() {
      // Styling
      const { classes } = this.props;
      let files = this.state.files;

    if (files){

      return (
        <div>
        <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>File Name</TableCell>
                    <TableCell align="right">File Size</TableCell>
                    <TableCell align="center">File Type</TableCell>
                    <TableCell align="center">Download Link</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files.map(file => (
                    <TableRow key={file.fullPath}>
                      <TableCell component="th" scope="row">
                        <a href={file.downloadUrl} target="_blank" rel="noopener noreferrer">{file.name}</a>
                      </TableCell>
                      <TableCell align="right">{file.size} bytes</TableCell>
                      <TableCell align="center">
                      { this.getIconForFileType(file.contentType) }
                      </TableCell>
                      <TableCell align="center"><a href={file.downloadUrl} target="_blank" rel="noopener noreferrer"><OpenInNewIcon /></a></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
        </TableContainer>
        </div>
      );
    }
  }

  render() {
    if(this.state.toHomepage === true){
      return <Redirect to='/' />
    }
    const { classes } = this.props;
    var selectedTab = this.state.selectedTab;

    if(!this.state.property || this.state.property.data() === undefined)
    {
      return (
        <Backdrop className={classes.backdrop} open={true} >
            <CircularProgress color="inherit" />
        </Backdrop>
      );
    }
    let propertyDocData = this.state.property.data();

    var propertyPhoto = '/property-photo-placeholder.jpg';
    if(propertyDocData.photo){
      propertyPhoto = propertyDocData.photo;
    }


    if (this.state.property) {
      console.log(this.state.property.data());
      return (
        <Paper className={classes.root}>

        <AppBar className={classes.tabBarButtonContainer} position="static" color="default">
          <Tabs
            value={selectedTab}
            onChange={this.handleTabChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Property Details" icon={<TuneIcon />} {...this.tabProps(0)} />
            <Tab label="Property Photos" icon={<PhotoLibraryIcon />} {...this.tabProps(1)} />
            <Tab label="Property Documents" icon={<DescriptionIcon />} {...this.tabProps(2)} />
            <Tab label="Property Map" icon={<MapIcon />} {...this.tabProps(3)} />
          </Tabs>
        </AppBar>

        <Typography component="h1" variant="h6">
        { propertyDocData.address }
        { this.rentedIcon( propertyDocData.rented ) }
        <Button className={classes.nav_button} onClick={this.handleRetunHome}><HomeIcon />Home</Button>
        </Typography>

        <Typography component="div" role="tabpanel"  hidden={ selectedTab !== 0 } {...this.tabContainerProps(0)} >

          <Grid container spacing={3}>
            <Grid item xs={4}>
              <img className={classes.responsive_image} src={propertyPhoto} alt={ propertyDocData.address } />
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2" color="textSecondary" component="p">
                {propertyDocData.bedrooms} bedrooms &bull; {propertyDocData.bathrooms} bathrooms <Typography component="br"></Typography> {propertyDocData.building_size} sqft {propertyDocData.type}
              </Typography>
            </Grid>
          </Grid>
        </Typography>

        <Typography component="div" role="tabpanel"  hidden={ selectedTab !== 1 }  {...this.tabContainerProps(1)} >
        { (propertyDocData.photo_list ) &&
        <GridList cellHeight={160} className={classes.gridList} cols={3}>
                {propertyDocData.photo_list.map(photo_item => (
                  <GridListTile key={photo_item} cols={1}>
                    <img src={photo_item} alt={propertyDocData.address} />
                  </GridListTile>
                ))}
        </GridList>
        }
      </Typography>

      <Typography component="div" role="tabpanel"  hidden={ selectedTab !== 2 }  {...this.tabContainerProps(2)}>

        <Toolbar className={classes.toolbar}>
          <Button onClick={this.handleUploadButtonClick}><CloudUploadIcon />&nbsp;Upload Documents</Button>
          <form id="fileUploadForm">
          <input onChange={this.handleFileList} ref={input => this.fileUploadInputControl = input} name="fileUpload" type="file" className={classes.fileUploadInput} id="documents" accept=".pdf,.doc,.docx,.pages,.zip" multiple />
          </form>
        </Toolbar>


      { this.renderFileList() }
      </Typography>

      <Typography component="div" role="tabpanel"  hidden={ selectedTab !== 3 }  {...this.tabContainerProps(3)}>
        { (propertyDocData.map ) &&
        <Iframe src={propertyDocData.map} width="100%" height="450" frameBorder="0"></Iframe>
        }
      </Typography>
      </Paper>
      );
    }
    return (
      <Backdrop className={classes.backdrop} open={true} >
          <CircularProgress color="inherit" />
      </Backdrop>
    );

  }

}

PropertyContent.propTypes = {
  // Properties
  user: PropTypes.object,
  propertyID: PropTypes.object
};

export default withStyles(styles) (PropertyContent);
