import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import PropTypes from 'prop-types';

import { fade, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PersonIcon from '@material-ui/icons/Person';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';


import authentication from '../../services/authentication';


const styles = theme => ({
  grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    appTitle:{
      paddingTop: theme.spacing(1),

    },
    titleImage: {
      //position: 'relative',
      //marginTop: 4,
      //marginRight: 4,
      //width: 24,
      //height: 24,
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 200,
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    list: {
        width: 250,
      },
      fullList: {
        width: 'auto',
      },
});


class Bar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: {
        anchorEl: null
      },
      menuOpen: false,
      goHome: false,
    };
  }

  toggleDrawer = (open) => event => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }

      this.setState({ menuOpen: open });
  };

  sideList = () => {
      const { classes } = this.props;
      return(

        <div
              className={classes.list}
              role="presentation"
              onClick={this.toggleDrawer(false)}
              onKeyDown={this.toggleDrawer(false)}
            >
              <List>
                {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                  <ListItem button key={text}>
                    <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItem>
                ))}
              </List>
              <Divider />
              <List>
                {['All mail', 'Trash', 'Spam'].map((text, index) => (
                  <ListItem button key={text}>
                    <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItem>
                ))}
              </List>
            </div>

      );
  }

  getAvatar = () => {
    const { user } = this.props;

    if (!user) {
      return null;
    }

    const photoUrl = user.photoURL;

    if (photoUrl) {
      return (<Avatar alt="Avatar" src={photoUrl} />);
    }

    const { userData } = this.props;

    if (!userData) {
      return <PersonIcon />;
    }

    const nameInitials = authentication.user.getNameInitials({
      ...user,
      ...userData
    });

    if (nameInitials) {
      return <Avatar alt="Avatar">{nameInitials}</Avatar>;
    }

    return <PersonIcon />;
  };

  openMenu = (event) => {
    const anchorEl = event.currentTarget;

    this.setState({
      menu: {
        anchorEl
      }
    });
  };

  closeMenu = () => {
    this.setState({
      menu: {
        anchorEl: null
      }
    });
  };
  goHome = (event) => {
    console.log("Go home (if necessary)...");
    console.log(window.location.pathname);
    if(window.location.pathname !== '/'){
      //this.setState({goHome: true});
      window.location.href = '/';
    }
  }

  handleAboutClick = () => {
    this.closeMenu();
    this.props.onAboutClick();
  };

  handleSettingsClick = () => {
    this.closeMenu();
    this.props.onSettingsClick();
  };

  handleSignOutClick = () => {
    this.closeMenu();
    this.props.onSignOutClick();
  };

  render() {
    if(this.state.goHome){
      return <Redirect to={'/'} />
    }
    // Properties
    const { performingAction, user } = this.props;

    const { classes } = this.props;

    // Events
    const {
      onSignUpClick,
      onSignInClick
    } = this.props;

    const { menu } = this.state;

    return (
      <AppBar color="primary" position="static">
        <Toolbar variant="regular">

        {user &&
        <div>
        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="open drawer" onClick={this.toggleDrawer(true)}>
          <MenuIcon />
        </IconButton>

        <Drawer open={this.state.menuOpen} onClose={this.toggleDrawer(false)}>
               {this.sideList()}
        </Drawer>
        </div>
        }
          <Box display="flex" flexGrow={1}>
            <IconButton onClick={this.goHome}>
              <img src="/logo24.png" alt="{process.env.REACT_APP_TITLE}" width="24" height="24" className={classes.titleImage}/>
            </IconButton>
            <Hidden xsDown>
              <Typography className={classes.appTitle} color="inherit" variant="h6">{process.env.REACT_APP_TITLE}</Typography>
            </Hidden>
          </Box>

        {user &&
          <Hidden xsDown>
          <div className={classes.search}>
                      <div className={classes.searchIcon}>
                        <SearchIcon />
                      </div>
                      <InputBase
                        placeholder="Search…"
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search' }}
                      />
          </div>
          </Hidden>
        }
          {user &&
            <>

            <IconButton aria-label="show 0 new notifications" color="inherit">
                          <Badge badgeContent={0} color="secondary">
                            <NotificationsIcon />
                          </Badge>
            </IconButton>



              <IconButton color="inherit" disabled={performingAction} onClick={this.openMenu}>
                {this.getAvatar()}
              </IconButton>

              <Menu anchorEl={menu.anchorEl} open={Boolean(menu.anchorEl)} onClose={this.closeMenu}>
                <MenuItem disabled={performingAction} onClick={this.handleAboutClick}>About</MenuItem>
                <MenuItem disabled={performingAction} onClick={this.handleSettingsClick}>Settings</MenuItem>
                <MenuItem disabled={performingAction} onClick={this.handleSignOutClick}>Sign out</MenuItem>
              </Menu>
            </>
          }

          {!user &&
            <>
              <Box mr={1}>
                <Button color="secondary" disabled={performingAction} variant="contained" onClick={onSignUpClick}>Sign Up</Button>
              </Box>

              <Button color="secondary" disabled={performingAction} variant="contained" onClick={onSignInClick}>Sign In</Button>
            </>
          }
        </Toolbar>
      </AppBar>
    );
  }
}

Bar.defaultProps = {
  performingAction: false
};

Bar.propTypes = {
  // Properties
  performingAction: PropTypes.bool.isRequired,
  user: PropTypes.object,
  userData: PropTypes.object,

  // Events
  onAboutClick: PropTypes.func.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  onSignOutClick: PropTypes.func.isRequired,
};

export default withStyles(styles) (Bar);
