import React, { Component } from 'react';
import PropTypes from 'prop-types';

import validate from 'validate.js';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';

import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import ReceiptIcon from '@material-ui/icons/Receipt';
import ApartmentIcon from '@material-ui/icons/Apartment';
import KingBedIcon from '@material-ui/icons/KingBed';
import BathtubIcon from '@material-ui/icons/Bathtub';
import StraightenIcon from '@material-ui/icons/Straighten';
import SaveIcon from '@material-ui/icons/Save';

import MapIcon from '@material-ui/icons/Map';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import constraints from '../../constraints';

import { firestore } from '../../firebase';


const styles = (theme) => ({
  dialogContent: {
    paddingTop: theme.spacing(2)
  },
  formControl: {
      margin: theme.spacing(1),
      width: '100%',
    },
  badge: {
    top: theme.spacing(2),
    right: -theme.spacing(2)
  },

  loadingBadge: {
    top: '50%',
    right: '50%'
  },

  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),

    minHeight: 'initial'
  }
});

const emptyPropertyObject = {
  address: '',
  latLng: '',
  type: '',
  bedrooms: 0,
  bathrooms: 1,
  building_size: 0,
  lot_size: 0,
  photo: '',
  photo_list: null,
  purchase_date: null,
  purchase_price: 0,
  rented: false
};

const initialState = {
  performingAction: false,
  errors: false,
  propertyApn: '',
  toPropertyDetail: false,
  propertyObject: emptyPropertyObject,
}

class NewPropertyTab extends Component {
  constructor(props) {
    super(props);
    console.log("NewPropertyTab Constructor");
    this.state = initialState;
  }

  handleKeyDown = (event, fieldId) => {
    if (!event || !fieldId) {
      return;
    }

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    const key = event.key;

    if (!key) {
      return;
    }

    if (key === 'Escape') {
      this.hideFields();
    } else if (key === 'Enter') {
      this.changeField(fieldId);
    }
  };

  handlePhotoUpload = (event) => {
    if (!event) {
      return;
    }

    const files = event.target.files;

    if (!files) {
      return;
    }

    const avatar = files[0];

    if (!avatar) {
      return;
    }

    const fileTypes = [
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/webp',
      'image/svg+xml'
    ];

    files.forEach(function(file, index){

      if (!fileTypes.includes(file.type)) {
        return;
      }

      if (file.size > (20 * 1024 * 1024)) {
        return;
      }

    });

  };

  handleAddressChange = (event) => {
    if (!event) {
      return;
    }

    const address = event.target.value;

    let updatedPropertyObject = this.state.propertyObject;
    updatedPropertyObject.addess = address;

    this.setState({
      propertyObject: updatedPropertyObject
    });
  };

  handleAPNChange = (event) => {
    if (!event) {
      return;
    }

    const apn = event.target.value;

    this.setState({
      propertyApn: apn
    });
  };

  handleTypeChange = (event) => {
    if (!event) {
      return;
    }

    const type = event.target.value;

    let updatedPropertyObject = this.state.propertyObject;
    updatedPropertyObject.type = type;

    this.setState({
      propertyObject: updatedPropertyObject
    });

  };


  handleBedroomsChange = (event) => {
    if (!event) {
      return;
    }

    const bedrooms = event.target.value;

    let updatedPropertyObject = this.state.propertyObject;
    updatedPropertyObject.bedrooms = bedrooms;

    this.setState({
      propertyObject: updatedPropertyObject
    });
  };

  handleBathroomsChange = (event) => {
    if (!event) {
      return;
    }

    const bathrooms = event.target.value;

    let updatedPropertyObject = this.state.propertyObject;
    updatedPropertyObject.bathrooms = bathrooms;

    this.setState({
      propertyObject: updatedPropertyObject
    });
  };

  handleBuildingSizeChange = (event) => {
    if (!event) {
      return;
    }

    const building_size = event.target.value;

    let updatedPropertyObject = this.state.propertyObject;
    updatedPropertyObject.building_size = building_size;

    this.setState({
      propertyObject: updatedPropertyObject
    });
  };

  handleLotSizeChange = (event) => {
    if (!event) {
      return;
    }

    const lot_size = event.target.value;

    let updatedPropertyObject = this.state.propertyObject;
    updatedPropertyObject.lot_size = lot_size;

    this.setState({
      propertyObject: updatedPropertyObject
    });
  };

  handleChange = address => {

    let updatedPropertyObject = this.state.propertyObject;
    updatedPropertyObject.address = address;

    this.setState({
      propertyObject: updatedPropertyObject
    });
  };

  handleSelect = address => {
    geocodeByAddress(address)
      .then(console.log(address))
      .then(results => getLatLng(results[0]))
      .then(
        latLng => {
          console.log('Success', latLng);

          let updatedPropertyObject = this.state.propertyObject;
          updatedPropertyObject.address = address;
          updatedPropertyObject.latLng = latLng;

          this.setState({
            propertyObject: updatedPropertyObject
          });
        }
      )
      .catch(error => console.error('Error', error));

  };

  onSavePropertyClick = (event) => {
    if (!event) {
      return;
    }
    console.log('Saving Property');

    let propertyApn = this.state.propertyApn;
    let updatedPropertyObject = this.state.propertyObject;
    let dialogProps = this.props.dialogProps;

    console.log(updatedPropertyObject.address);
    console.log(updatedPropertyObject.apn);
    console.log(updatedPropertyObject.latLng);

    var docRef = firestore.collection('properties').doc(propertyApn);
    docRef.get()
    .then(function(doc){
        if(doc.exists){
            console.error('Property exists for this APN. ', propertyApn);
        } else {
            firestore.collection('properties').doc(propertyApn).set(updatedPropertyObject)
            .then(function(){
              console.log("New Property Document successfully written!");
              dialogProps.onClose('newPropertyDialog');
            })
            .catch(function(error){
              console.error('Error writing new Property Document:', error);
            });
        }
    });

/*
7333 Ravines
125-10-115-019

11833 Amistoso
137-34-717-033
1882 sqft
3 bedroom 2.5 bathroom
*/

  }


  render() {
    // Styling
    const { classes } = this.props;

    const searchOptions = {
      types: ['address']
    }
    const bedroomCountList = [];

    for(var i=0; i < 20; i++){
      var bedroomKey = 'bedroomCount-' + i;
      bedroomCountList.push(<MenuItem key={bedroomKey} value={i}>{i}</MenuItem>);
    }

    const bathroomCountList = [];

    for(let i=0; i < 10; i++){
      for(var j=0; j < 3; j++){
        var bathroomCount = i;
        if(j === 1){
            bathroomCount += 0.5;
        } else if (j === 2) {
          bathroomCount += 0.75;
        }
        var bathroomKey = 'bathroomCount-' + bathroomCount;
        bathroomCountList.push(<MenuItem key={bathroomKey} value={bathroomCount}>{bathroomCount}</MenuItem>);
      }
    }

    return (
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Box mb={2}>
        </Box>

        <List disablePadding>

        <ListItem>
          <Hidden xsDown>
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
          </Hidden>

            <TextField
              autoComplete="apn"
              fullWidth
              helperText='Enter the Assessor Parcel Number (APN)'
              label="Assessor Parcel Number (APN)"
              required
              type="text"
              value={this.state.propertyApn}
              variant="filled"
              autoFocus
              onKeyDown={(event) => this.handleKeyDown(event, 'apn')}
              onChange={this.handleAPNChange}
            />

        </ListItem>

          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <MapIcon />
              </ListItemIcon>
            </Hidden>

            <PlacesAutocomplete
              value={this.state.propertyObject.address}
              onChange={this.handleChange}
              onSelect={this.handleSelect}
              searchOptions={searchOptions}
            >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div className="MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth">
                  <TextField
                  {...getInputProps({
                    placeholder: 'Search Places ...',
                    className: 'location-search-input',
                  })}
                    autoComplete="property-address"
                    fullWidth
                    helperText='Type and select the matching address'
                    label="Property Address"
                    required
                    type="text"
                    variant="filled"
                    onBlur={this.hideFields}
                  />

                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map(suggestion => {
                      console.log(suggestion);
                      const className = suggestion.active
                        ? 'suggestionItemActive'
                        : 'suggestionItem';
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: '#ffff66', color: '#666666' , cursor: 'pointer' }
                        : { cursor: 'pointer' };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>


          </ListItem>

          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <ApartmentIcon />
              </ListItemIcon>
            </Hidden>
            <FormControl variant="filled" className={classes.formControl}>
                <InputLabel id="type-select-helper-label">Property Type</InputLabel>
                <Select
                   labelId="type-select-helper-label"
                    id="type-select-helper"
                    value={this.state.propertyObject.type}
                    onChange={this.handleTypeChange}
                    required
                  >
                    <MenuItem value='single family residence'>single family residence</MenuItem>
                    <MenuItem value='condominium'>condominium</MenuItem>
                    <MenuItem value='townhouse'>townhouse</MenuItem>
                    <MenuItem value='manufactured'>manufactured</MenuItem>
                    <MenuItem value='apartment'>apartment</MenuItem>
                  </Select>
                  <FormHelperText>Select the appropriate property type</FormHelperText>
              </FormControl>
            </ListItem>


            <ListItem>
              <Hidden xsDown>
                <ListItemIcon>
                  <KingBedIcon />
                </ListItemIcon>
              </Hidden>
              <FormControl variant="filled" className={classes.formControl}>
                  <InputLabel id="bedrooms-select-helper-label">Bedrooms</InputLabel>
                  <Select
                     labelId="bedrooms-select-helper-label"
                      id="bedrooms-select-helper"
                      value={this.state.propertyObject.bedrooms}
                      onChange={this.handleBedroomsChange}
                      required
                    >
                    { bedroomCountList }
                    </Select>
                    <FormHelperText>Select the bedroom count</FormHelperText>
                </FormControl>
              </ListItem>

              <ListItem>
                <Hidden xsDown>
                  <ListItemIcon>
                    <BathtubIcon />
                  </ListItemIcon>
                </Hidden>
                <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel id="bathrooms-select-helper-label">Bathrooms</InputLabel>
                    <Select
                       labelId="bathrooms-select-helper-label"
                        id="bathrooms-select-helper"
                        value={this.state.propertyObject.bathrooms}
                        onChange={this.handleBathroomsChange}
                        required
                      >
                      { bathroomCountList }
                      </Select>
                      <FormHelperText>Select the bathroom count</FormHelperText>
                  </FormControl>
                </ListItem>

                <ListItem>
                  <Hidden xsDown>
                    <ListItemIcon>
                      <StraightenIcon />
                    </ListItemIcon>
                  </Hidden>

                    <TextField
                      autoComplete="building_size"
                      fullWidth
                      helperText='Enter the square footage of the interior of this property'
                      label="Interior Square Footage"
                      required
                      type="text"
                      value={this.state.propertyObject.building_size}
                      variant="filled"

                      onKeyDown={(event) => this.handleKeyDown(event, 'building_size')}

                      onChange={this.handleBuildingSizeChange}
                    />

                </ListItem>



          <Box mt={1} mb={1}>
            <Divider light />
          </Box>

          <ListItem>
            <Hidden xsDown>
              <ListItemIcon>
                <SaveIcon />
              </ListItemIcon>
            </Hidden>

            <ListItemText
              primary="Save New Property"
              secondary="You will add more info to this property later..."
            />

            <ListItemSecondaryAction>
              <Button color="primary" variant="contained" onClick={this.onSavePropertyClick}>Save</Button>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </DialogContent>
    );
  }

}

NewPropertyTab.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,
  // Properties
  user: PropTypes.object.isRequired,
  userData: PropTypes.object,
  // Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(NewPropertyTab);
