import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import NewPropertyTab from '../NewPropertyTab';


const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1)
  },
  requiredNote: {
    color: '#ff0000'
  }
});

class NewPropertyDialog extends Component {

  render() {
    // Styling
    const { classes } = this.props;

    // Dialog Properties
    const { dialogProps } = this.props;

    // Custom Properties
    const {
      user,
      userData,
    } = this.props;

    // Custom Functions
    const {
      openSnackbar
    } = this.props;

    return (
      <Dialog {...dialogProps} onExited={this.handleExited}>
      <DialogTitle>
            Add New Property
          <Tooltip title="Close">
            <IconButton className={classes.closeButton} onClick={dialogProps.onClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          Please provide the requested information below to create a new property record.&nbsp;
          <strong>Assessor Parcel Number (APN)</strong> is the unique identifier that the local government
          has for the property and will be the unique key for this property.&nbsp;
          <span className={classes.requiredNote}>*</span>All fields are required<span className={classes.requiredNote}>*</span>.
          </DialogContentText>
          <NewPropertyTab
              user={user}
              userData={userData}
              openSnackbar={openSnackbar}
              dialogProps={dialogProps}
            />
        </DialogContent>
      </Dialog>
    );
  }
}

NewPropertyDialog.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Dialog Properties
  dialogProps: PropTypes.object.isRequired,

  // Custom Properties
  user: PropTypes.object.isRequired,
  userData: PropTypes.object,
  theme: PropTypes.object.isRequired,

  // Custom Functions
  openSnackbar: PropTypes.func.isRequired,

};

export default withStyles(styles)(NewPropertyDialog);
