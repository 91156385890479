import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import CheckCircle from '@material-ui/icons/CheckCircle';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import { green } from '@material-ui/core/colors';
import { grey } from '@material-ui/core/colors';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import { firestore } from '../../firebase';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  rootContainer: {
    position: 'relative'
  },
  fabContainer: {
    position: 'relative',

  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  grid: {
    margin: 20,
  },
  card: {
    maxWidth: 345,
    margin: 12,
  },
  fab: {
      //position: 'absolute',
      top: theme.spacing(2),
      left: theme.spacing(2),
      backgroundColor: green[500],
    },
});



class PropertyList extends React.Component{


  constructor(props){
    super(props);
    this.state = {
      properties: [],
      toPropertyDetail: false,
      propertyDetailID: null,
      addDialogOpen: false,
    }

    var that = this;

    var query = firestore.collection('properties').limit(50);
    query.onSnapshot(function(snapshot){
      console.log('Property Collection has updated...');
        if(!snapshot.size){
            //There are no properties...
        } else {
            let property_list = [];
            snapshot.forEach(function(doc){
              property_list.push(doc);
              //console.log(property_list);
              that.setState({properties: property_list});
            });
        }
    });

  }

  handleAddPropertyClick = () => {
    console.log('Add Property Clicked');
    this.props.onAddPropertyClick();
  };

  propertyClick(id){
    //alert('Clicked ID ' + id);
    console.log('Clicked ID ' + id);
    this.setState(() => ({ toPropertyDetail: true, propertyDetailID: id }))
  }

  rentedIcon(isRented){
    if(isRented === true){
      return(
        <CheckCircle style={{ color: green[500], float: 'right' }} />
      );
    } else {
      return(
        <CheckCircleOutline style={{ color: grey[500], float: 'right' }} />
      );
    }

  }

  renderProperty(doc, classes) {
    //console.log(doc);
    var propertyPhoto = '/property-photo-placeholder.jpg';
    if(doc.data().photo){
      propertyPhoto = doc.data().photo;
    }

    return (
        <Grid key={doc.id} item>
        <Card id={doc.id} className={classes.card}>
          <CardActionArea onClick={ () => { this.propertyClick(doc.id); }}>
            <CardMedia
              component="img"
              alt={doc.data().address}
              height="140"
              image={propertyPhoto}
              title={doc.data().address}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {doc.data().address}
                { this.rentedIcon( doc.data().rented ) }
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {doc.data().bedrooms} bedrooms &bull; {doc.data().bathrooms} bathrooms <Typography component="br"></Typography> {doc.data().building_size} sqft {doc.data().type}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary"  onClick={ () => { this.propertyClick(doc.id); }}>
              Details
            </Button>
          </CardActions>
        </Card>
        </Grid>
      );
    }

  render() {
    if(this.state.toPropertyDetail === true){
      return <Redirect to={'/property/' + this.state.propertyDetailID} />
    }
    const { classes } = this.props;
    var that = this;
    return (
      <Container className={classes.rootContainer} maxWidth="lg">
      <div className={classes.fabContainer}>
      <Fab aria-label="Add New Property" className={classes.fab} onClick={this.handleAddPropertyClick}><AddIcon /></Fab>
      </div>
      <Grid container spacing={1} className={classes.root}>
      <Grid item xs={12}>
      <Grid container justify="center" spacing={1}>
      { that.state.properties.map((doc, index) => {
            return (that.renderProperty(doc, classes));
      })}
      </Grid>
      </Grid>
      </Grid>

      </Container>
    )
  };
}

PropertyList.defaultProps = {
  performingAction: false,
};

PropertyList.propTypes = {
  // Properties
  performingAction: PropTypes.bool.isRequired,
  user: PropTypes.object,
  userData: PropTypes.object,

  // Events
  onAddPropertyClick: PropTypes.func.isRequired,
};

export default withStyles(styles) (PropertyList);
